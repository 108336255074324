import * as React from 'react';
import { RouteObject } from 'react-router';
import LoginPage from '../Authentication/components/AuthenticationPage/LoginPage.tsx';
import SignupPage from '../Authentication/components/AuthenticationPage/SignupPage.tsx';
import TermsConditions from '../Authentication/components/AuthenticationPage/TermsConditions.tsx';
import { PublicRoute } from '../Core/components/PrivateRoutes/PublicRoute.tsx';
import { dashboardCountersLoader } from '../Dashboard/api/dashboard.api';
import { lazyRetry } from '../lazyRetry.tsx';

const Company = React.lazy(() => lazyRetry(() => import('../Company/components/CompanyPage.tsx'), 'CompanyPage'));
const Profile = React.lazy(() => lazyRetry(() => import('../Core/components/Profile/Profile.tsx'), 'Profile'));

export const commonPublicRoutes: RouteObject[] = [
  {
    index: true,
    Component: () => (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: '/login',
    Component: () => (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: '/signup',
    Component: () => (
      <PublicRoute>
        <SignupPage />
      </PublicRoute>
    ),
  },
  {
    path: '/terms-and-conditions',
    Component: () => (
      <PublicRoute>
        <TermsConditions />
      </PublicRoute>
    ),
  },
];

export const commonPrivateRoutes: RouteObject[] = [
  {
    path: '/dashboard',
    loader: dashboardCountersLoader,
    lazy: () =>
      lazyRetry(
        () => import('../Dashboard/components/Dashboard').then((module) => ({ Component: module.Dashboard })),
        'Dashboard',
      ),
  },
  {
    path: '/notifications',
    lazy: () =>
      lazyRetry(
        () =>
          import('../Notifications/components/Notifications.tsx').then((module) => ({
            Component: module.Notifications,
          })),
        'Notifications',
      ),
  },
  {
    path: '/notifications/:id',
    lazy: () =>
      lazyRetry(
        () =>
          import('../Notifications/components/NotificationPage.tsx').then((module) => ({
            Component: module.NotificationPage,
          })),
        'NotificationPage',
      ),
  },
  {
    path: '/profile',
    Component: Profile,
  },
  {
    path: '/company',
    Component: Company,
    children: [
      {
        index: true,
        lazy: () =>
          lazyRetry(
            () =>
              import('../Company/components/CompanyProfile.tsx').then((module) => ({
                Component: module.CompanyProfile,
              })),
            'CompanyProfile',
          ),
      },
      {
        path: 'profile',
        lazy: () =>
          lazyRetry(
            () =>
              import('../Company/components/CompanyProfile.tsx').then((module) => ({
                Component: module.CompanyProfile,
              })),
            'CompanyProfile',
          ),
      },
      {
        path: 'settings',
        lazy: () =>
          lazyRetry(
            () =>
              import('../Company/components/CompanySettings.tsx').then((module) => ({
                Component: module.CompanySettings,
              })),
            'CompanySettings',
          ),
      },
      {
        path: 'trash',
        lazy: () =>
          lazyRetry(
            () =>
              import('../Company/components/CompanyTrash.tsx').then((module) => ({ Component: module.CompanyTrash })),
            'CompanyTrash',
          ),
      },
      {
        path: 'audits',
        lazy: () =>
          lazyRetry(
            () =>
              import('../Company/components/CompanyAudits.tsx').then((module) => ({ Component: module.CompanyAudits })),
            'CompanyAudits',
          ),
      },
    ],
  },
];
