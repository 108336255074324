import { Box, Link, Tooltip } from '@chakra-ui/react';
import clsx from 'clsx';
import * as React from 'react';
import { useLocation } from 'react-router';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSidebarCollapsed } from '../../Core/hooks/useSidebarCollapsed';

interface TIconProps {
  icon: React.ReactNode;
  isCollapsed: boolean;
  label: string;
}

const TooltipIcon: React.FC<React.PropsWithChildren<TIconProps>> = ({ isCollapsed, icon, label, children }) => {
  if (isCollapsed) {
    return (
      <Tooltip hasArrow aria-label={label} bg="white" color="#333" label={label} placement="right" zIndex={1000}>
        <Box display="flex" width="100%" alignItems="center" minHeight="20px">
          <Box verticalAlign="middle" textAlign="center" fontSize="20px" minW="20px" boxSize="28px">
            {icon}
          </Box>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Box display="flex" width="100%" alignItems="center" minHeight="20px">
      <Box verticalAlign="middle" textAlign="center" fontSize="20px" minW="20px" boxSize="28px">
        {icon}
      </Box>
      <span>{children}</span>
    </Box>
  );
};
TooltipIcon.displayName = 'TooltipIcon';

interface MenuItemProps {
  to?: string;
  label: string;
  icon: React.ReactNode;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

export const MenuItemLink: React.FC<React.PropsWithChildren<MenuItemProps>> = ({
  to = '/',
  label,
  icon,
  onClick,
  onMouseEnter,
}) => {
  const isCollapsed = useSidebarCollapsed();
  const { pathname } = useLocation();
  const classes = clsx('Sidebar-menu__item', { 'Sidebar-menu__item--active': pathname.includes(to) });

  return (
    <li className={classes}>
      <Link
        as={RouterLink}
        to={to}
        _focus={{ outline: 'none' }}
        className="link"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        fontSize="0.75rem"
        fontWeight="semibold"
        cursor="pointer"
      >
        <TooltipIcon isCollapsed={isCollapsed} icon={icon} label={label}>
          {label}
        </TooltipIcon>
      </Link>
    </li>
  );
};

MenuItemLink.displayName = 'MenuItemLink';
