import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createBrowserRouter } from 'react-router';
import { authUserLoader } from '../Authentication/store/authenticatedUser.api';
import { cashPaymentLoader, cashPaymentsLoader } from '../CashPayments/api/payments.api';
import { clientLoader, clientsLoader } from '../Clients/api/clients.api';
import { AppLogout } from '../Core/components/AutoLogout.tsx';
import { LoadingBrojki } from '../Core/components/BrojkiLogo/LoadingBrojki.tsx';
import { Navbar } from '../Core/components/Navbar/Navbar.tsx';
import { PrivateRoute } from '../Core/components/PrivateRoutes/PrivateRoute.tsx';
import { estimateLoader, estimatesLoader } from '../Estimates/api/estimates.api';
import { expenseLoader, expensesLoader } from '../Expenses/api/expenses.api';
import { invoiceLoader, invoicesLoader } from '../Invoices/api/invoices.api';
import { productLoader, productsLoader } from '../Products/api/products.api';
import { projectLoader, projectsLoader } from '../Projects/api/projects.api';
import { AppContainer, Root, RouteNotFound } from '../Root.tsx';
import { EstimateCreatorDataLoader, InvoiceCreatorDataLoader } from '../Treasuries/components/TreasuryDataLoader.tsx';
import { lazyRetry } from '../lazyRetry.tsx';
import { commonPrivateRoutes, commonPublicRoutes } from './commonRoutes.tsx';

const Error500Boundary = React.lazy(() =>
  lazyRetry(() => import('../Core/ErrorBoundary/Error500Boundary.tsx'), 'Error500Boundary'),
);
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

export const mobileRouter = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      Component: Root,
      HydrateFallback: LoadingBrojki,
      ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
      children: [
        ...commonPublicRoutes,
        {
          ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
          loader: authUserLoader,
          Component: () => (
            <PrivateRoute>
              <AppLogout />
              <Navbar />
              <AppContainer />
            </PrivateRoute>
          ),
          children: [
            ...commonPrivateRoutes,
            {
              path: '/invoices/*',
              loader: invoicesLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../Invoices/components/InvoicesMobile.tsx').then((module) => ({
                      Component: module.Invoices,
                    })),
                  'InvoicesMobile',
                ),
            },
            {
              path: '/invoices/:id',
              loader: invoiceLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../Invoices/components/InvoiceLoader.tsx').then((module) => ({
                      Component: module.InvoicePageLoader,
                    })),
                  'InvoicePageLoader',
                ),
            },
            {
              path: '/invoices/:id/edit',
              loader: invoiceLoader,
              Component: InvoiceCreatorDataLoader,
            },
            {
              path: '/estimates/*',
              loader: estimatesLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../Estimates/components/EstimatesMobile.tsx').then((module) => ({
                      Component: module.Estimates,
                    })),
                  'Estimates',
                ),
              children: [
                {
                  index: true,
                  lazy: () =>
                    lazyRetry(
                      () =>
                        import('../Estimates/components/EstimatesHome.tsx').then((module) => ({
                          Component: module.EstimatesHome,
                        })),
                      'EstimatesHome',
                    ),
                },
                {
                  path: ':id',
                  loader: estimateLoader,
                  children: [
                    {
                      index: true,
                      loader: estimateLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Estimates/components/EstimateLoader.tsx').then((module) => ({
                              Component: module.EstimatePageLoader,
                            })),
                          'EstimatePageLoader',
                        ),
                    },
                    {
                      path: 'edit',
                      loader: estimateLoader,
                      Component: EstimateCreatorDataLoader,
                    },
                  ],
                },
              ],
            },
            {
              path: '/expenses/*',
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../Expenses/components/ExpensesMobile.tsx').then((module) => ({
                      Component: module.Expenses,
                    })),
                  'ExpensesMobile',
                ),
              loader: expensesLoader,
              children: [
                {
                  index: true,
                  lazy: () =>
                    lazyRetry(
                      () =>
                        import('../Expenses/components/ExpensesHome.tsx').then((module) => ({
                          Component: module.ExpensesHome,
                        })),
                      'ExpensesHome',
                    ),
                },
                {
                  path: ':id',
                  loader: expenseLoader,
                  children: [
                    {
                      index: true,
                      loader: expenseLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Expenses/components/ExpenseLoader.tsx').then((module) => ({
                              Component: module.ExpensePageLoader,
                            })),
                          'ExpensePageLoader',
                        ),
                    },
                    {
                      path: 'edit',
                      loader: expenseLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Expenses/components/ExpenseCreatorLoader.tsx').then((module) => ({
                              Component: module.ExpenseCreatorLoader,
                            })),
                          'ExpenseCreatorLoader',
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: '/cash-payments/*',
              loader: cashPaymentsLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../CashPayments/components/CashPaymentsMobile.tsx').then((module) => ({
                      Component: module.CashPayments,
                    })),
                  'CashPaymentsMobile',
                ),
              children: [
                {
                  index: true,
                  lazy: () =>
                    lazyRetry(
                      () =>
                        import('../CashPayments/components/CashPaymentsHome.tsx').then((module) => ({
                          Component: module.CashPaymentsHome,
                        })),
                      'CashPaymentsHome',
                    ),
                },
                {
                  path: ':id',
                  loader: cashPaymentLoader,
                  children: [
                    {
                      index: true,
                      loader: cashPaymentLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../CashPayments/components/CashPaymentLoader.tsx').then((module) => ({
                              Component: module.CashPaymentPageLoader,
                            })),
                          'CashPaymentPageLoader',
                        ),
                    },
                    {
                      path: 'edit',
                      loader: cashPaymentLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../CashPayments/components/CashPaymentCreatorLoader.tsx').then((module) => ({
                              Component: module.CashPaymentCreatorLoader,
                            })),
                          'CashPaymentCreatorLoader',
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: '/clients/*',
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../Clients/components/ClientsMobile.tsx').then((module) => ({ Component: module.Clients })),
                  'ClientsMobile',
                ),
              loader: clientsLoader,
              children: [
                {
                  index: true,
                  lazy: () =>
                    lazyRetry(
                      () =>
                        import('../Clients/components/ClientsHome.tsx').then((module) => ({
                          Component: module.ClientsHome,
                        })),
                      'ClientsHome',
                    ),
                },
                {
                  path: ':id',
                  loader: clientLoader,
                  children: [
                    {
                      index: true,
                      loader: clientLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Clients/components/ClientLoader.tsx').then((module) => ({
                              Component: module.ClientPageLoader,
                            })),
                          'ClientPageLoader',
                        ),
                    },
                    {
                      path: 'edit',
                      loader: clientLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Clients/components/ClientCreatorLoader.tsx').then((module) => ({
                              Component: module.ClientCreatorLoader,
                            })),
                          'ClientCreatorLoader',
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: '/projects/*',
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../Projects/components/ProjectsMobile.tsx').then((module) => ({
                      Component: module.Projects,
                    })),
                  'ProjectsMobile',
                ),
              loader: projectsLoader,
              children: [
                {
                  index: true,
                  lazy: () =>
                    lazyRetry(
                      () =>
                        import('../Projects/components/ProjectsHome.tsx').then((module) => ({
                          Component: module.ProjectsHome,
                        })),
                      'ProjectsHome',
                    ),
                },
                {
                  path: ':id',
                  loader: projectLoader,
                  children: [
                    {
                      index: true,
                      loader: projectLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Projects/components/ProjectLoader.tsx').then((module) => ({
                              Component: module.ProjectPageLoader,
                            })),
                          'ProjectPageLoader',
                        ),
                    },
                    {
                      path: 'edit',
                      loader: projectLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Projects/components/ProjectCreatorLoader.tsx').then((module) => ({
                              Component: module.ProjectCreatorLoader,
                            })),
                          'ProjectCreatorLoader',
                        ),
                    },
                  ],
                },
              ],
            },
            {
              path: '/products/*',
              loader: productsLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import('../Products/components/ProductsMobile.tsx').then((module) => ({
                      Component: module.Products,
                    })),
                  'ProductsMobile',
                ),
              children: [
                {
                  index: true,
                  lazy: () =>
                    lazyRetry(
                      () =>
                        import('../Products/components/ProductsHome.tsx').then((module) => ({
                          Component: module.ProductsHome,
                        })),
                      'ProductsHome',
                    ),
                },
                {
                  path: ':id',
                  loader: productLoader,
                  children: [
                    {
                      index: true,
                      loader: productLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Products/components/ProductLoader.tsx').then((module) => ({
                              Component: module.ProductPageLoader,
                            })),
                          'ProductPageLoader',
                        ),
                    },
                    {
                      path: 'edit',
                      loader: productLoader,
                      lazy: () =>
                        lazyRetry(
                          () =>
                            import('../Products/components/ProductCreatorLoader.tsx').then((module) => ({
                              Component: module.ProductCreatorLoader,
                            })),
                          'ProductCreatorLoader',
                        ),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      Component: RouteNotFound,
    },
  ],
  {
    future: {
      v7_partialHydration: true,
    },
  },
);
