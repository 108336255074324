import * as Sentry from '@sentry/react';
import * as React from 'react';
import { createBrowserRouter } from 'react-router';
import { authUserLoader } from '../Authentication/store/authenticatedUser.api';
import { cashPaymentLoader, cashPaymentsLoader } from '../CashPayments/api/payments.api';
import { clientLoader, clientsLoader } from '../Clients/api/clients.api';
import { AppLogout } from '../Core/components/AutoLogout.tsx';
import { LoadingBrojki } from '../Core/components/BrojkiLogo/LoadingBrojki.tsx';
import { Navbar } from '../Core/components/Navbar/Navbar.tsx';
import { PrivateRoute } from '../Core/components/PrivateRoutes/PrivateRoute.tsx';
import { estimateLoader, estimatesLoader } from '../Estimates/api/estimates.api';
import { expenseLoader, expensesLoader } from '../Expenses/api/expenses.api';
import { invoiceLoader, invoicesLoader } from '../Invoices/api/invoices.api';
import { productLoader, productsLoader } from '../Products/api/products.api';
import { projectLoader, projectsLoader } from '../Projects/api/projects.api';
import { AppContainer, Root, RouteNotFound } from '../Root.tsx';
import { EstimateCreatorDataLoader, InvoiceCreatorDataLoader } from '../Treasuries/components/TreasuryDataLoader.tsx';
import { lazyRetry } from '../lazyRetry.tsx';
import { commonPrivateRoutes, commonPublicRoutes } from './commonRoutes.tsx';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter);

export const Error500Boundary = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Error500Boundary" */ '../Core/ErrorBoundary/Error500Boundary.tsx'),
    'Error500Boundary',
  ),
);

export const desktopRouter = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      Component: Root,
      HydrateFallback: LoadingBrojki,
      ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
      children: [
        ...commonPublicRoutes,
        {
          ErrorBoundary: process.env.NODE_ENV === 'development' ? undefined : Error500Boundary,
          loader: authUserLoader,
          Component: () => (
            <PrivateRoute>
              <AppLogout />
              <Navbar />
              <AppContainer />
            </PrivateRoute>
          ),
          children: [
            ...commonPrivateRoutes,
            {
              path: '/invoices',
              loader: invoicesLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "Invoices" */ '../Invoices/components/Invoices.tsx').then((module) => ({
                      Component: module.Invoices,
                    })),
                  'Invoices',
                ),
            },
            {
              path: '/invoices/:id',
              loader: invoiceLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "InvoicePageLoader" */ '../Invoices/components/InvoiceLoader.tsx').then(
                      (module) => ({ Component: module.InvoicePageLoader }),
                    ),
                  'InvoicePageLoader',
                ),
            },
            {
              path: '/invoices/:id/edit',
              loader: invoiceLoader,
              Component: InvoiceCreatorDataLoader,
            },
            {
              path: '/estimates',
              loader: estimatesLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "Estimates" */ '../Estimates/components/Estimates.tsx').then(
                      (module) => ({ Component: module.Estimates }),
                    ),
                  'Estimates',
                ),
            },
            {
              path: '/estimates/:id',
              loader: estimateLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "EstimatePageLoader" */ '../Estimates/components/EstimateLoader.tsx'
                    ).then((module) => ({ Component: module.EstimatePageLoader })),
                  'EstimatePageLoader',
                ),
            },
            {
              path: '/estimates/:id/edit',
              loader: estimateLoader,
              Component: EstimateCreatorDataLoader,
            },
            {
              path: '/expenses',
              loader: expensesLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "Expenses" */ '../Expenses/components/Expenses.tsx').then((module) => ({
                      Component: module.Expenses,
                    })),
                  'Expenses',
                ),
            },
            {
              path: '/expenses/:id',
              loader: expenseLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "ExpensePageLoader" */ '../Expenses/components/ExpenseLoader.tsx').then(
                      (module) => ({ Component: module.ExpensePageLoader }),
                    ),
                  'ExpensePageLoader',
                ),
            },
            {
              path: '/expenses/:id/edit',
              loader: expenseLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ExpenseCreatorLoader" */ '../Expenses/components/ExpenseCreatorLoader.tsx'
                    ).then((module) => ({ Component: module.ExpenseCreatorLoader })),
                  'ExpenseCreatorLoader',
                ),
            },
            {
              path: '/cash-payments',
              loader: cashPaymentsLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "CashPayments" */ '../CashPayments/components/CashPayments.tsx').then(
                      (module) => ({ Component: module.CashPayments }),
                    ),
                  'CashPayments',
                ),
            },
            {
              path: '/cash-payments/:id',
              loader: cashPaymentLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "CashPaymentPageLoader" */ '../CashPayments/components/CashPaymentLoader.tsx'
                    ).then((module) => ({ Component: module.CashPaymentPageLoader })),
                  'CashPaymentPageLoader',
                ),
            },
            {
              path: '/cash-payments/:id/edit',
              loader: cashPaymentLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "CashPaymentCreatorLoader" */ '../CashPayments/components/CashPaymentCreatorLoader.tsx'
                    ).then((module) => ({ Component: module.CashPaymentCreatorLoader })),
                  'CashPaymentCreatorLoader',
                ),
            },
            {
              path: '/clients',
              loader: clientsLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "Clients" */ '../Clients/components/Clients.tsx').then((module) => ({
                      Component: module.Clients,
                    })),
                  'Clients',
                ),
            },
            {
              path: '/clients/:id',
              loader: clientLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "ClientPageLoader" */ '../Clients/components/ClientLoader.tsx').then(
                      (module) => ({ Component: module.ClientPageLoader }),
                    ),
                  'ClientPageLoader',
                ),
            },
            {
              path: '/clients/:id/edit',
              loader: clientLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ClientCreatorLoader" */ '../Clients/components/ClientCreatorLoader.tsx'
                    ).then((module) => ({ Component: module.ClientCreatorLoader })),
                  'ClientCreatorLoader',
                ),
            },
            {
              path: '/projects',
              loader: projectsLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "Projects" */ '../Projects/components/Projects.tsx').then((module) => ({
                      Component: module.Projects,
                    })),
                  'Projects',
                ),
            },
            {
              path: '/projects/:id',
              loader: projectLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "ProjectPageLoader" */ '../Projects/components/ProjectLoader.tsx').then(
                      (module) => ({ Component: module.ProjectPageLoader }),
                    ),
                  'ProjectPageLoader',
                ),
            },
            {
              path: '/projects/:id/edit',
              loader: projectLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ProjectCreatorLoader" */ '../Projects/components/ProjectCreatorLoader.tsx'
                    ).then((module) => ({ Component: module.ProjectCreatorLoader })),
                  'ProjectCreatorLoader',
                ),
            },
            {
              path: '/products',
              loader: productsLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "Products" */ '../Products/components/Products.tsx').then((module) => ({
                      Component: module.Products,
                    })),
                  'Products',
                ),
            },
            {
              path: '/products/:id',
              loader: productLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(/* webpackChunkName: "ProductPageLoader" */ '../Products/components/ProductLoader.tsx').then(
                      (module) => ({ Component: module.ProductPageLoader }),
                    ),
                  'ProductPageLoader',
                ),
            },
            {
              path: '/products/:id/edit',
              loader: productLoader,
              lazy: () =>
                lazyRetry(
                  () =>
                    import(
                      /* webpackChunkName: "ProductCreatorLoader" */ '../Products/components/ProductCreatorLoader.tsx'
                    ).then((module) => ({ Component: module.ProductCreatorLoader })),
                  'ProductCreatorLoader',
                ),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      Component: RouteNotFound,
    },
  ],
  {
    future: {
      v7_partialHydration: true,
    },
  },
);
